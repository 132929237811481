import { gql } from '@apollo/client'
import { formatISO } from 'date-fns'
import {
  BREEDER_DATA,
  CUSTOMER_DATA,
  CHAT_DATA,
  MEETING_DATA,
  BREEDER_RESERVATIONS_DATA,
  BASIC_BREED_DATA,
  BREED_CATEGORIES,
} from './fragments'
export * from './admin'
export * from './dogApplications'

export const CUSTOMER_LOGIN = gql`
  mutation loginCustomer($customer: LoginCustomer) {
    loginCustomer(customer: $customer) {
      ... on JWT {
        jwt
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const LOGIN_CUSTOMER_SOCIAL = gql`
  mutation loginCustomerSocial($customer: SocialLogin) {
    loginCustomerSocial(customer: $customer) {
      ... on JWT {
        jwt
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const BREEDER_LOGIN = gql`
  mutation loginBreeder($breeder: LoginBreeder) {
    loginBreeder(breeder: $breeder) {
      ... on JWT {
        jwt
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const ADMIN_LOGIN = gql`
  mutation loginAdmin($admin: LoginAdmin) {
    loginAdmin(admin: $admin) {
      ... on JWT {
        jwt
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_BREEDER_DATA = gql`
  query getBreederData {
    breeder {
      ...BreederData
      licenses {
        id
        original
        filename
        description
        type
        download
      }
      dogs(available: true) {
        id
        breed {
          id
          name
          profilePicture {
            id
            small
            medium
            high
          }
        }
      }
      breeds {
        ...BreedsBasicData
        profilePicture {
          id
          small
        }
      }
    }
  }
  ${BREEDER_DATA}
  ${BASIC_BREED_DATA}
`

export const GET_PUBLIC_BREEDER_DATA = gql`
  query getPublicBreederData($id: Int, $limit: Int) {
    breeder(id: $id) {
      ...BreederData
      breeds {
        id
        name
      }

      breedsCount(id: $id, limit: $limit) {
        id
        name
      }
      dogs(available: true) {
        id
        name
        slug
        gender
        price
        currency
        registrations
        isAvailable
        profilePicture {
          id
          small
        }
        images {
          id
          small
        }
        videos {
          id
          medium
          high
          thumb
        }
        salePrice {
          subTotal
          puppyPrice
        }
        breed {
          id
          name
          profilePicture {
            id
            small
            medium
            high
          }
        }
      }
    }
  }
  ${BREEDER_DATA}
`

export const GET_BREEDER_DASHBOARD_DATA = gql`
  query getBreederDashboardData {
    breeder {
      ...BreederData
      dogs {
        id
      }
      meetings (first: 3, canceled: false, after: "${formatISO(new Date())}") {
        id
        date
        title
        online
        url
        location
        breederAccepted
        customerAccepted
        canceled
        customer{
          firstName
          lastName
        }
      }
    }
  }
  ${BREEDER_DATA}
`

export const GET_BREEDER_APPLICATION_DATA = gql`
  query getBreederApplicationData {
    breeds {
      id
      name
    }
  }
`

export const SUBMIT_BREEDER_APPLICATION = gql`
  mutation submitApplication($breederApplication: SubmitBreederApplication) {
    submitApplication(breederApplication: $breederApplication) {
      ... on BreederApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const CREATE_BREEDER = gql`
  mutation createBreeder($breeder: CreateBreeder) {
    createBreeder(breeder: $breeder) {
      ... on JWT {
        jwt
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($customer: CreateCustomer) {
    createCustomer(customer: $customer) {
      ... on JWT {
        jwt
      }
      ... on Error {
        errorMessage
        errorList {
          field
          error
        }
      }
    }
  }
`

export const CREATE_AMBASSADOR = gql`
  mutation createAmbassador($user: CreateAmbassador) {
    createAmbassador(user: $user) {
      ... on Ambassador {
        id
        verified
        email
        hash
      }
      ... on Error {
        errorMessage
        errorList {
          field
          error
        }
      }
    }
  }
`
export const VERIFY_AMBASSADOR_EMAIL = gql`
  mutation verifyAmbassadorEmail($hash: String) {
    verifyAmbassadorEmail(hash: $hash) {
      ... on Ambassador {
        id
        verified
        email
        hash
      }

      ... on Error {
        errorMessage
      }
    }
  }
`

export const RESEND_EMAIL_AMBASSADOR = gql`
  mutation resendAmbassadorEmail($data: ResendAmbassadorEmail) {
    resendAmbassadorEmail(data: $data) {
      ... on Ambassador {
        id
        email
      }

      ... on Error {
        errorMessage
      }
    }
  }
`
export const GET_AMBASSADOR = gql`
  query getAmbassador($hash: String, $code: String) {
    getAmbassador(hash: $hash, code: $code) {
      id
      name
      lastName
      code
      email
      verified
      amount
    }
  }
`
export const CREATE_CUSTOMER_SOCIAL = gql`
  mutation createCustomerSocial($customer: SocialLogin) {
    createCustomerSocial(customer: $customer) {
      ... on JWT {
        jwt
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const VERIFY_BREEDER = gql`
  mutation verifyBreeder($hash: String) {
    verifyBreeder(hash: $hash) {
      ... on JWT {
        jwt
      }

      ... on Error {
        errorMessage
      }
    }
  }
`

export const VERIFY_CUSTOMER = gql`
  mutation verifyCustomer($hash: String) {
    verifyCustomer(hash: $hash) {
      ... on JWT {
        jwt
      }

      ... on Error {
        errorMessage
      }
    }
  }
`

export const NEW_PET_INITIAL_DATA = gql`
  query newPetInitialData {
    breeds {
      id
      name
      profilePicture {
        small
      }
    }
    breeder {
      id
      dogs {
        id
        name
        isParent
        gender
        breed {
          id
          name
        }
        profilePicture {
          small
        }
      }
      litters {
        id
        name
        birthDate
        breedId
        updatedAt
        puppies {
          name
          gender
          size
        }
        momId
        dadId
        automaticallyCreated
      }
    }
    dogType: __type(name: "Dog") {
      fields {
        name
        type {
          kind
          name
          enumValues {
            name
          }
        }
      }
    }
  }
`

export const GET_PARENTS = gql`
  query getParents {
    parents {
      id
      name
      gender
      profilePicture {
        small
      }
      breedId
    }
  }
`

export const CREATE_PET = gql`
  mutation createDog($dog: CreateDog, $litter: CreateDogLitter) {
    createDog(dog: $dog, litter: $litter) {
      ... on Dog {
        id
        name
        isParent
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPDATE_PET = gql`
  mutation updateDog($dog: UpdateDog) {
    updateDog(dog: $dog) {
      ... on Dog {
        id
        name
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const CREATE_DOG_LITTER = gql`
  mutation createDogLitter($dogLitter: CreateDogLitter) {
    createDogLitter(dogLitter: $dogLitter) {
      ... on DogLitter {
        id
        name
        breedId
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPDATE_DOG_LITTER = gql`
  mutation updateDogLitter($dogLitter: UpdateDogLitter) {
    updateDogLitter(dogLitter: $dogLitter) {
      ... on DogLitter {
        id
        name
        breedId
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const DELETE_DOG_LITTER = gql`
  mutation deleteDogLitter($litter: DeleteDogLitter) {
    deleteDogLitter(dogLitter: $litter) {
      ... on DogLitter {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const DELETE_PARENT_TO_DOG = gql`
  mutation deleteParentToDog($dog: DeleteParentToDog) {
    deleteParentToDog(dog: $dog) {
      ... on DogLitter {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPDATE_BREEDER = gql`
  mutation updateBreeder($breeder: UpdateBreeder) {
    updateBreeder(breeder: $breeder) {
      ... on Breeder {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_DOGS = gql`
  query getPets {
    breeder {
      id
      dogs {
        id
        name
        description
        isParent
        isAvailable
        gender
        weight
        age
        gender
        price
        microchipped
        geneticTesting
        bloodline
        training
        currency
        size
        color
        hypoallergenic
        registrations
        profilePictureId
        createdAt
        profilePicture {
          small
          medium
        }
        images {
          id
          small
        }
        videos {
          id
          medium
          high
          thumb
        }
        breed {
          id
          name
          discount(active: true, applyDiscountByDefault: true) {
            id
            code
            applyDiscountByDefault
            amount
          }
        }
        litter {
          name
          id
          breedId
          birthDate
          automaticallyCreated
          mom {
            id
            name
            description
            color
            weight
            breed {
              id
              name
            }
            profilePicture {
              small
              medium
              high
            }
          }
          dad {
            id
            name
            description
            color
            weight
            breed {
              id
              name
            }
            profilePicture {
              small
              medium
              high
            }
          }
        }
        shippingOptions {
          id
          type
        }
      }
      litters {
        id
        name
        birthDate
        availabilityDate
        breedId
        mom {
          id
          name
        }
        dad {
          id
          name
        }
        puppies {
          id
          name
          gender
        }
      }
    }
  }
`

export const DELETE_DOG = gql`
  mutation deleteDog($dog: DeleteDog) {
    deleteDog(dog: $dog) {
      ... on Dog {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const SOLD_DOG = gql`
  mutation soldDog($dog: SoldDog) {
    soldDog(dog: $dog) {
      ... on Dog {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPDATE_BREEDER_PROFILE_PICTURE = gql`
  mutation updateBreederProfilePicture(
    $profilePicture: UpdateBreederProfilePicture
  ) {
    updateBreederProfilePicture(profilePicture: $profilePicture) {
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPLOAD_BREEDER_LICENCE = gql`
  mutation uploadBreederLicence($licence: UploadBreederLicence) {
    uploadBreederLicence(licence: $licence) {
      ... on Document {
        id
        original
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const DELETE_BREEDER_LICENCE = gql`
  mutation deleteLicence($licence: DeleteLicence) {
    deleteBreederLicence(licence: $licence) {
      ... on Document {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_BREEDER_APPLICATIONS = gql`
  query getBreederApplications {
    breederApplications {
      id
      name
      description
      kennelSize
      kennelLicence
      hasKennelAssociation
      kennelAssociation
      litterFrequency
      retireLitters
      beginBreeding
      retireBreeding
      retirePlace
      breedingQuarters
      vetFrequency
      contract
      contractReturn
      hasSocialAccount
      websiteUrl
      facebookUrl
      instagramUrl
      linkedInUrl
      story
      vetCareData
      socialize
      price
      currency
      travelAssistance
      travelAssistanceOptions
      registration
      hasHealthGuarantee
      healthGuaranteeYears
      healthGuarantee
      healthTestBreedingDog
      healthTestBreedingDogDetails
      guaranteeDuration
      guaranteePeriod
      presale
      hearAboutUs
      breeder {
        ...BreederData
        breeds {
          name
        }
      }
    }
  }
  ${BREEDER_DATA}
`

export const GET_BREEDER_NOTIFICATIONS = gql`
  query getBreederNotifications {
    notifications {
      ... on BreederNotificationInterface {
        notificationId
        read
      }
      ... on BreederNotificationAnnouncement {
        title
        body
        buttonTitle
        buttonHref
      }
      ... on BreederNotificationSale {
        saleId
      }
      ... on BreederNotificationSchedule {
        meetingId
        meeting {
          ...MeetingData
        }
      }
      ... on BreederNotificationReschedule {
        meetingId
        meeting {
          ...MeetingData
        }
      }
      __typename
    }
  }
  ${MEETING_DATA}
`

export const SET_BREEDER_NOTIFICAITON_READ = gql`
  mutation ($notifications: ReadNotification) {
    readNotifications(notifications: $notifications) {
      ... on UnreadNotifications {
        count
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const ACCEPT_MEETING = gql`
  mutation ($meeting: AcceptMeeting) {
    acceptMeeting(meeting: $meeting) {
      ... on BreederCustomerMeeting {
        ...MeetingData
      }
      ... on Error {
        errorMessage
      }
    }
  }
  ${MEETING_DATA}
`

export const SCHEDULE_MEETING = gql`
  mutation ($meeting: CreateMeeting) {
    createMeeting(meeting: $meeting) {
      ... on BreederCustomerMeeting {
        ...MeetingData
      }
      ... on Error {
        errorMessage
      }
    }
  }
  ${MEETING_DATA}
`

export const RESCHEDULE_MEETING = gql`
  mutation ($meeting: RescheduleMeeting) {
    rescheduleMeeting(meeting: $meeting) {
      ... on BreederCustomerMeeting {
        ...MeetingData
      }
      ... on Error {
        errorMessage
      }
    }
  }
  ${MEETING_DATA}
`

export const GET_BREEDER_MEETINGS = gql`
  query meetingList(
    $first: Int
    $canceled: Boolean
    $after: String
    $before: String
  ) {
    breeder {
      id
      meetings(
        first: $first
        canceled: $canceled
        after: $after
        before: $before
      ) {
        ...MeetingData
      }
      chats {
        ... on BreederCustomerChatInterface {
          id
          breederId
          customerId
        }
      }
    }
  }
  ${MEETING_DATA}
`

export const GET_CUSTOMER_MEETINGS = gql`
  query meetingList(
    $first: Int
    $canceled: Boolean
    $after: String
    $before: String
  ) {
    customer {
      id
      meetings(
        first: $first
        canceled: $canceled
        after: $after
        before: $before
      ) {
        ...MeetingData
      }
      chats {
        ... on BreederCustomerChatInterface {
          id
          breederId
          customerId
        }
      }
    }
  }
  ${MEETING_DATA}
`

export const START_BREEDER_PASSWORD_RESET = gql`
  mutation startBreederPasswordReset($email: String) {
    startBreederPasswordReset(email: $email) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const FINISH_BREEDER_PASSWORD_RESET = gql`
  mutation finishBreederPasswordReset($breeder: ResetBreederPassword) {
    finishBreederPasswordReset(breeder: $breeder) {
      ... on JWT {
        jwt
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPDATE_CUSTOMER_PAYMENT_VIEW_COUNT = gql`
  mutation customerVisitedPayment($paymentType: String, $dogId: Int) {
    customerVisitedPayment(paymentType: $paymentType, dogId: $dogId) {
      ... on Error {
        errorMessage
      }
    }
  }
`

export const SEND_SLACK_MESSAGE = gql`
  mutation sendSlackMessage($message: String) {
    sendSlackMessage(message: $message) {
      ... on Error {
        errorMessage
      }
    }
  }
`

export const START_CUSTOMER_PASSWORD_RESET = gql`
  mutation startCustomerPasswordReset($email: String) {
    startCustomerPasswordReset(email: $email) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const FINISH_CUSTOMER_PASSWORD_RESET = gql`
  mutation finishCustomerPasswordReset($customer: ResetCustomerPassword) {
    finishCustomerPasswordReset(customer: $customer) {
      ... on JWT {
        jwt
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_CUSTOMER_DATA = gql`
  query getCustomerData {
    customer {
      ...CustomerData
    }
  }
  ${CUSTOMER_DATA}
`

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($customer: UpdateCustomer) {
    updateCustomer(customer: $customer) {
      ... on Customer {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPDATE_CUSTOMER_PROFILE_PICTURE = gql`
  mutation updateCustomerProfilePicture(
    $profilePicture: UpdateCustomerProfilePicture
  ) {
    updateCustomerProfilePicture(profilePicture: $profilePicture) {
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_CUSTOMER_FAVOURITES = gql`
  query getCustomerData {
    customer {
      favourites {
        id
        slug
        name
        isParent
        gender
        profilePicture {
          id
          small
          medium
        }
        breed {
          id
          name
        }
        litter {
          id
        }
      }
    }
  }
`

export const GET_CUSTOMER_NOTIFICATIONS = gql`
  query getCustomerNotifications {
    notifications {
      ... on CustomerNotificationInterface {
        notificationId
        read
      }
      ... on CustomerNotificationAnnouncement {
        title
        body
        buttonTitle
        buttonHref
      }
      ... on CustomerNotificationApplicationApproved {
        applicationId
        application {
          id
          proposedShippingPrice
          proposedShippingPriceAccepted
          approved
          canceled
          breederId
          dog {
            name
          }
        }
      }
      ... on CustomerNotificationOrderShipped {
        applicationId
        application {
          id
          approved
          canceled
          dog {
            name
          }
          order {
            id
            shippingProvider
            trackingNumber
            shipmentStatus
          }
        }
      }
      ... on CustomerNotificationSchedule {
        meetingId
      }
      ... on CustomerNotificationReschedule {
        meetingId
      }
      __typename
    }
  }
`

export const GET_UNREAD_NOTIFICATIONS = gql`
  query getUnreadNotifications {
    unreadNotifications {
      count
    }
  }
`

export const SET_CUSTOMER_NOTIFICATION_READ = gql`
  mutation ($notifications: ReadNotification) {
    readNotifications(notifications: $notifications) {
      ... on UnreadNotifications {
        count
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_ALL_DOGS = gql`
  query getAllDogsForAdmin($cursor: String, $limit: Int) {
    getAllDogsForAdmin(cursor: $cursor, limit: $limit) {
      results {
        id
        name
        slug
        isParent
        gender
        price
        currency
        salePrice {
          price
          deposit
          fapFee
          feesTaxes
          subTotal
          total
          puppyPrice
        }
        createdAt
        description
        isAvailable
        registrations
        createdAt
        profilePicture {
          id
          small
          medium
        }
        images {
          small
          medium
          high
        }
        videos {
          id
          medium
          high
          thumb
        }
        breeder {
          id
          firstName
          lastName
          companyName
          city
          state
          country
          zipcode
        }
        breed {
          id
          name
          emotional_support
          kid_friendly
          guard
          hunting
          hypoallergenic
          apartment
          small
        }
        litter {
          id
          name
          # birthDate
          availabilityDate
        }
      }
      cursors {
        hasNext
        nextCursor
      }
    }
  }
`
export const GET_DOG = gql`
  query getDog($id: Int, $slug: String) {
    dog(id: $id, slug: $slug) {
      id
      name
      gender
      isParent
      price
      slug
      salePrice {
        price
        deposit
        transaction
        fapFee
        subTotal
        total
        reminder
        puppyPrice
      }
      currency
      isAvailable
      breederId
      breed {
        name
        discount(active: true, applyDiscountByDefault: true) {
          id
          amount
          code
          applyDiscountByDefault
        }
      }
      profilePicture {
        small
        medium
        high
      }
      shippingOptions {
        id
        type
      }
      discount(active: true, applyDiscountByDefault: true) {
        id
        code
        amount
        applyDiscountByDefault
      }
    }
  }
`

const QUERY_BREEDS = `
  breeds {
    id
    name
    slug
    profilePicture {
      id
      small
      medium
      high
    }
    coverPicture {
      id
      small
      medium
      high
    }
    mobileCoverPicture {
      id
      small
      medium
      high
    }
    discount(active: true){
      id
      code
      applyDiscountByDefault
      amount
    }
    dogs(available: true) {
      id
      name
      slug
      isParent
      isAvailable
      gender
      price
      currency
      discount(active: true, applyDiscountByDefault: true) {
        id
        code
        applyDiscountByDefault
        amount
      }
      profilePicture {
        id
        small
        medium
        high
      }
      images {
        id
        small
      }
      videos {
        id
        medium
        high
        thumb
      }
      salePrice {
        subTotal
        puppyPrice
      }
      breed {
        id
        name
        discount(active: true, applyDiscountByDefault: true){
          id
          code
          applyDiscountByDefault
          amount
        }
      }
      breeder {
        id
        firstName
        lastName
        companyName
      }
    }
  }
`

export const GET_HOME_BREEDS = gql`
  query getPublicBreeds($first: Int) {
    breeds {
      id
      name
      slug
      dogs(available: true, first: $first) {
        id
        name
        slug
        gender
        price
        currency
        profilePicture {
          id
          small
          medium
          high
        }
        images {
          id
          small
        }
        videos {
          id
          medium
          high
          thumb
        }
        salePrice {
          subTotal
          puppyPrice
        }
        breed {
          id
          name
          discount(active: true) {
            id
            amount
            code
            applyDiscountByDefault
          }
        }
      }
    }
  }
`

export const GET_ALL_BREEDS = gql`
  query getPublicBreeds {
    ${QUERY_BREEDS}
  }
`
export const GET_FAV_DOG = gql`
  query getFavDog($id: Int) {
    favouriteDogCount(id: $id) {
      count
    }
  }
`
export const GET_VIEWS_DOG = gql`
  query getViewsDog($id: Int) {
    viewsDogCount(id: $id) {
      dogId
      count
    }
  }
`
export const GET_DOG_DETAIL = gql`
  query getDogDetail($selected: Int, $slug: String) {
    dog(id: $selected, slug: $slug) {
      id
      name
      slug
      description
      profilePictureId
      gender
      breedId
      color
      age
      size
      weight
      isParent
      dogLitterId
      breederId
      price
      currency
      microchipped
      geneticTesting
      bloodline
      training
      salePrice {
        puppyPrice
        klarnaQuote
        price
        deposit
        fapFee
        feesTaxes
        subTotal
        total
      }
      discount(active: true, applyDiscountByDefault: true) {
        id
        amount
        code
        applyDiscountByDefault
      }
      hypoallergenic
      personality
      registrations
      isAvailable
      litter {
        id
        birthDate
        availabilityDate
        mom {
          id
          name
          description
          registrations
          color
          weight
          breed {
            id
            name
          }
          profilePicture {
            small
            medium
            high
          }
        }
        dad {
          id
          name
          description
          registrations
          color
          weight
          breed {
            id
            name
          }
          profilePicture {
            small
            medium
            high
          }
        }
      }
      breeder {
        zipcode
        application {
          hasHealthGuarantee
          healthGuaranteeYears
          healthGuarantee
          microchipped
          dnaTested
          guaranteeDuration
          guaranteePeriod
        }
        id
        firstName
        lastName
        email
        companyName
        city
        state
        companyLogo {
          thumb
          small
        }
        yearsOfExperience
      }
      breed {
        id
        name
        slug
        description
        kid_friendly
        hypoallergenic
        emotional_support
        weightMin
        weightMax
        profilePicture {
          id
          small
          medium
        }
        discount(active: true, applyDiscountByDefault: true) {
          id
          amount
          code
          applyDiscountByDefault
        }
      }
      profilePicture {
        id
        small
        medium
        high
      }
      images {
        id
        small
        medium
        high
      }
      videos {
        id
        thumb
        medium
        high
        original
      }
      dogApplications {
        id
        dogId
        customerId
        canceled
        order {
          id
        }
      }
    }
  }
`

export const GET_CHAT_LIST = gql`
  query chatList {
    chats {
      ... on BreederCustomerChatInterface {
        ...Chat
        breeder {
          companyLogo {
            thumb
            small
          }
        }
      }
      ... on BreederCustomerChatMessage {
        ...ChatMessage
      }
      ... on BreederCustomerChatImage {
        ...ChatImage
      }
      __typename
    }
  }
  ${CHAT_DATA}
`

export const GET_CHAT_MESSAGES = gql`
  query chatMessages($breederId: Int, $customerId: Int, $today: String) {
    breeder(id: $breederId) {
      ...BreederData
    }
    chats(breederId: $breederId, customerId: $customerId) {
      ... on BreederCustomerChatInterface {
        ...Chat
      }
      ... on BreederCustomerChatMessage {
        ...ChatMessage
      }
      ... on BreederCustomerChatImage {
        ...ChatImage
      }
      __typename
    }
    customer(id: $customerId) {
      ...CustomerData
    }
    meetings(
      breederId: $breederId
      customerId: $customerId
      canceled: false
      first: 1
      after: $today
    ) {
      ...MeetingData
    }
  }
  ${CUSTOMER_DATA}
  ${BREEDER_DATA}
  ${CHAT_DATA}
  ${MEETING_DATA}
`

export const GET_CUSTOMER_CHAT_LIST = gql`
  query chatList {
    customer {
      id
      chats {
        ... on BreederCustomerChatInterface {
          ...Chat
        }
        ... on BreederCustomerChatMessage {
          ...ChatMessage
        }
        ... on BreederCustomerChatImage {
          ...ChatImage
        }
        __typename
      }
    }
  }
  ${CHAT_DATA}
`

export const GET_CUSTOMER_CHAT_MESSAGES = gql`
  query chatMessages($breederId: Int, $today: String) {
    breeder(id: $breederId) {
      ...BreederData
    }
    customer {
      ...CustomerData
      chats(breederId: $breederId) {
        ... on BreederCustomerChatInterface {
          ...Chat
        }
        ... on BreederCustomerChatMessage {
          ...ChatMessage
        }
        ... on BreederCustomerChatImage {
          ...ChatImage
        }
        __typename
      }
      meetings(
        breederId: $breederId
        canceled: false
        first: 1
        after: $today
      ) {
        ...MeetingData
      }
    }
  }
  ${BREEDER_DATA}
  ${CUSTOMER_DATA}
  ${CHAT_DATA}
  ${MEETING_DATA}
`

export const CREATE_CHAT_MESSAGE = gql`
  mutation createChatMessage($message: CreateChatMessage) {
    createChatMessage(message: $message) {
      ... on BreederCustomerChatBase {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const GET_BREEDS_QUIZ = gql`
  query getPuppyQuiz($quiz: SaveCustomerQuizOptions) {
    quiz(quiz: $quiz) {
      id
      slug
      name
      weightMin
      weightMax
      size
      energyLevel
      amountOfShedding
      easyToTrain
      lifeSpanMin
      lifeSpanMax
      description
      dogs {
        name
        slug
        gender
        id
        isAvailable
        breed {
          id
          name
        }
        salePrice {
          price
          puppyPrice
          subTotal
        }
        profilePicture {
          small
          medium
        }
        images {
          id
          small
          medium
        }
      }
      coverPicture {
        id
        small
      }
    }
  }
`

export const SAVE_CUSTOMER_BREEDS_QUIZ = gql`
  mutation saveCustomerQuizOptions($quiz: SaveCustomerQuizOptions) {
    saveCustomerQuizOptions(quiz: $quiz) {
      ... on CustomerQuizOptionsType {
        hasOwnedDog
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const GET_BREED_DETAILS = gql`
  query getBreedDetails($id: Int, $slug: String) {
    breed(id: $id, slug: $slug) {
      ...BreedsBasicData
      description
      size
      weightMin
      weightMax
      energyLevel
      amountOfShedding
      lifeSpanMin
      lifeSpanMax
      easyToTrain
      temperament
      # Categories #
      ...BreedCategories
      # Pictures #
      profilePicture {
        id
        small
        # medium
        # high
      }
      coverPicture {
        id
        small
        # medium
        # high
      }
      mobileCoverPicture {
        id
        small
        # medium
        # high
      }
      relatedBreeds {
        id
        name
        slug
        dogsAvailable
      }
      discount(active: true) {
        id
        code
        startDate
        endDate
        applyDiscountByDefault
        allowToAllDogs
        amount
        breeds {
          id
          name
        }
      }
    }
  }
  ${BASIC_BREED_DATA}
  ${BREED_CATEGORIES}
`

export const GET_LANDINGS_BREED_DETAILS = gql`
  query getBreedDetails($id: Int, $slug: String) {
    breed(id: $id, slug: $slug) {
      ...BreedsBasicData
      description
      size
      weightMin
      weightMax
      energyLevel
      amountOfShedding
      lifeSpanMin
      lifeSpanMax
      easyToTrain
      temperament
      # Categories #
      ...BreedCategories
      # Pictures #
      profilePicture {
        id
        small
      }
      coverPicture {
        id
        small
      }
      mobileCoverPicture {
        id
        small
      }
    }
  }
  ${BASIC_BREED_DATA}
  ${BREED_CATEGORIES}
`

const QUERY_CATEGORIES = `
  categories{
    small
    emotional_support
    affordable
    kid_friendly
    apartment 
    hypoallergenic
    doodle
    service
    active
    guard
    therapy
    trained
  }
`

const QUERY_SEARCH_BREEDS = `
  dogsCountByBreed{
    name
    slug
    count
    hasDogs
    profilePicture
  }
`

export const GET_CATEGORIES = gql`
  query getCategories {
    ${QUERY_CATEGORIES}
  }
`
export const PUPPY_SEARCH = gql`
  query search(
    $breedId: Int
    $category: String
    $zipcode: String
    $price: Int
    $size: String
    $gender: String
    $hypoallergenic: Boolean
    $color: String
    $breedSlug: String
    $state: String
    $limit: Int
    $sort: String
    $frontendConfigData: String
    $page: Int
  ) {
    search(
      breedId: $breedId
      category: $category
      zipcode: $zipcode
      price: $price
      size: $size
      gender: $gender
      hypoallergenic: $hypoallergenic
      color: $color
      breedSlug: $breedSlug
      state: $state
      limit: $limit
      sort: $sort
      frontendConfigData: $frontendConfigData
      page: $page
    ) {
      rows {
        id
        name
        slug
        gender
        currency
        color
        viewScore
        isAvailable
        salePrice {
          puppyPrice
          subTotal
        }
        discount(active: true) {
          id
          code
          startDate
          endDate
          applyDiscountByDefault
          allowToAllDogs
          amount
        }
        breed {
          id
          name
          slug
          hypoallergenic
          size
          profilePicture {
            id
            thumb
            small
            medium
          }
          discount(active: true) {
            id
            code
            startDate
            endDate
            applyDiscountByDefault
            allowToAllDogs
            amount
          }
        }
        breeder {
          id
          city
          state
          firstName
          lastName
          companyName
        }
        profilePicture {
          id
          thumb
          small
          medium
        }
        images {
          id
          small
          medium
        }
        videos {
          id
          medium
          high
          thumb
        }
        city
        state
        distance
        views
        registrations
        bloodline
        training
      }
      count
    }
  }
`

export const UPDATE_FAVOURITE = gql`
  mutation updateFavourite($dog: Int) {
    updateFavourite(dog: $dog) {
      ... on Dog {
        id
        name
        isParent
        gender
        profilePicture {
          id
          small
          medium
        }
        breed {
          id
          name
        }
        litter {
          id
        }
      }
    }
  }
`
export const CREATE_DOGVISIT = gql`
  mutation createDogVisit($dog: Int) {
    createDogVisit(dog: $dog) {
      ... on Dog {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_STRIPE_DATA = gql`
  query GetStripeData {
    stripe {
      onboarded
      active
      error
      onboardingLink
      loginLink
    }
  }
`

export const GET_STRIPE_LINK = gql`
  mutation generateStripeOnboardingLink {
    generateStripeOnboardingLink {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_ALL_IMAGES = gql`
  query getAllImages {
    images {
      id
      small
      medium
      high
      original
      createdAt
    }
  }
`

export const ROTATE_IMAGE = gql`
  mutation rotateImage($image: RotateImage) {
    rotateImage(image: $image) {
      ... on Image {
        id
        small
      }
      ... on Error {
        errorMessage
      }
      __typename
    }
  }
`

export const ADD_PAYMENT_METHOD = gql`
  mutation addPaymentMethod($payment: AddPayment) {
    addPaymentMethod(payment: $payment) {
      ... on Customer {
        id
      }
      ... on Error {
        errorMessage
      }
      __typename
    }
  }
`

export const GET_FEES = gql`
  query getFees {
    fees {
      type
      amount
      amountType
    }
  }
`
export const GET_DOG_SHIPPING_DISTANCE = gql`
  query getDogShippingDistance($zipcode: String, $dogId: Int) {
    dogShippingDistance(zipcode: $zipcode, dogId: $dogId) {
      distance
      error
    }
  }
`

export const GET_RELATED_PUPPIES = gql`
  query getRelatedPuppies($id: Int, $slug: String) {
    relatedPuppies(id: $id, slug: $slug) {
      id
      name
      slug
      gender
      salePrice {
        subTotal
        puppyPrice
      }
      currency
      images {
        id
        small
      }
      videos {
        id
        medium
        high
        thumb
      }
      profilePicture {
        id
        small
        medium
      }
      breed {
        id
        name
      }
    }
  }
`

export const GET_ALL_FRONTEND_CONFIG = gql`
  query getAllFrontendConfigs {
    allFrontendConfigs {
      key
      value
    }
  }
`

const QUERY_FRONTEND_CONFIG_LANDING_PHOTOS = `
  frontendConfig(key: "landing-photos") {
    value
  }
`

export const GET_FRONTEND_CONFIG_LANDING_PHOTOS = gql`
  query getFrontendConfigLandingPhotos {
    ${QUERY_FRONTEND_CONFIG_LANDING_PHOTOS}
  }
`

export const GET_FRONTEND_CONFIG_LANDING = gql`
  query getFrontendConfigLanding($key: String) {
    frontendConfig(key: $key) {
      value
    }
  }
`

export const SET_FRONTEND_CONFIG = gql`
  mutation setFrontendConfig($frontendConfig: SetFrontendConfig) {
    setFrontendConfig(frontendConfig: $frontendConfig) {
      ... on FrontendConfigType {
        key
        value
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_NEARBY_DOGS = gql`
  query nearbyStateDogs($borderingStates: [String]) {
    nearbyStateDogs(borderingStates: $borderingStates) {
      id
      name
      slug
      gender
      currency
      isAvailable
      salePrice {
        subTotal
        puppyPrice
      }
      breed {
        id
        name
        slug
      }
      breeder {
        id
        city
        state
      }
      profilePicture {
        id
        thumb
        small
        medium
      }
      images {
        id
        small
        medium
      }
      videos {
        id
        medium
        high
        thumb
      }
    }
  }
`
export const GET_RESERVED_DOGS = gql`
  query reservedDogs($breedSlug: String, $category: String, $state: String) {
    reservedDogs(breedSlug: $breedSlug, category: $category, state: $state) {
      id
      name
      slug
      gender
      currency
      color
      isAvailable
      salePrice {
        puppyPrice
        subTotal
      }
      discount(active: true) {
        id
        code
        startDate
        endDate
        applyDiscountByDefault
        allowToAllDogs
        amount
      }
      breed {
        id
        name
        slug

        size
        profilePicture {
          id
          thumb
          small
          medium
        }
        discount(active: true) {
          id
          code
          startDate
          endDate
          applyDiscountByDefault
          allowToAllDogs
          amount
        }
      }
      breeder {
        id
        city
        state
        firstName
        lastName
        companyName
      }
      profilePicture {
        id
        thumb
        small
        medium
      }
      images {
        id
        small
        medium
      }
      videos {
        id
        medium
        high
        thumb
      }
    }
  }
`
export const GET_ALL_DOGS_HOME = gql`
  query getAllPets($isAvailable: Boolean, $profilePictureId: Boolean) {
    dogs(isAvailable: $isAvailable, profilePictureId: $profilePictureId) {
      id
      name
      breeder {
        id
        firstName
        lastName
        companyName
        city
        state
        country
        zipcode
      }
    }
  }
`

export const GET_STATE = gql`
  query state($code: String) {
    state(code: $code) {
      id
      code
      country
      history
      facts
      name
      slug
      stateCoverPictureId
      stateMobileCoverPictureId
      coverPicture {
        id
        small
        medium
        high
      }
      mobileCoverPicture {
        id
        small
        medium
        high
      }
    }
  }
`

export const GET_STATE_LANDING = gql`
  query state($code: String) {
    state(code: $code) {
      id
      code
      country
      history
      facts
      name
      slug
    }
  }
`

export const GET_STATE_FACTS_STATE_LANDING = gql`
  query state($code: String) {
    state(code: $code) {
      id
      code
      country
      history
      facts
      name
      slug
      stateCoverPictureId
      stateMobileCoverPictureId
      coverPicture {
        id
        small
        medium
        high
      }
      mobileCoverPicture {
        id
        small
        medium
        high
      }
    }
  }
`

const QUERY_LOCATION = `
  locations {
    country
    states {
      state
      slug
      count
    }
  }
`

export const GET_ALL_LOCATIONS = gql`
  query getAllLocations {
    ${QUERY_LOCATION}
  }
`

const EXPLORE_BREEDS = `
  breedsExplorer {
    withDogs {
      id
      name
      slug
      hasDogs
      count
      profilePicture {
        id
        small
      }
    }
    withoutDogs {
      id
      name
      slug
      hasDogs
      count
      profilePicture {
        id
        small
      }
    }
  }
`

export const GET_EXPLORE_BREEDS = gql`
  query getExploreBreeds($withoutDogsLimit: Int) {
    breedsExplorer(withoutDogsLimit: $withoutDogsLimit) {
      withDogs {
        id
        name
        slug
        hasDogs
        count
        profilePicture {
          id
          small
        }
      }
      withoutDogs {
        id
        name
        slug
        hasDogs
        count
        profilePicture {
          id
          small
        }
      }
    }
  }
`

export const GET_BREEDS_NAME_LIST = gql`
  query getBreedsNameList($withoutDogsLimit: Int) {
    breedsExplorer(withoutDogsLimit: $withoutDogsLimit) {
      withDogs {
        id
        name
        slug
        hasDogs
        count
      }
      withoutDogs {
        id
        name
        slug
        hasDogs
        count
      }
    }
  }
`

export const HOME_PAGE = gql`
  query HOME_PAGE {
    ${QUERY_BREEDS}
    ${QUERY_CATEGORIES}
    ${EXPLORE_BREEDS}
    ${QUERY_FRONTEND_CONFIG_LANDING_PHOTOS}
    ${QUERY_LOCATION}
  }
`
export const SITE_MAP = gql`
query SITE_MAP {
  breeds {
      id
      name
      slug
    }
  ${QUERY_CATEGORIES}
}
`

export const SEARCH_DATA = gql`
  query SEARCH_DATA {
    ${QUERY_CATEGORIES}
    ${QUERY_SEARCH_BREEDS}
  }
`

export const GET_DOGS_BY_NAME = gql`
  query dogsByName($name: String) {
    dogsByName(name: $name) {
      id
      name
      slug
      profilePicture {
        small
      }
    }
  }
`

export const GET_ALL_BREEDS_LIST = gql`
  query getBreeds {
    breedsList {
      id
      name
      slug
      profilePicture {
        id
        small
      }
      dogs {
        id
        name
        slug
        gender
        salePrice {
          subTotal
        }
        breed {
          name
        }
        profilePicture {
          id
          small
          medium
          high
        }
        images {
          id
          small
        }
        videos {
          id
          medium
          high
          thumb
        }
      }
    }
  }
`

export const QUERY_DOGS_COUNT_BY_BREED = gql`
  query QUERY_DOGS_COUNT_BY_BREED {
    dogsCountByBreed {
      id
      name
      slug
      count
      profilePicture
    }
  }
`

export const QUERY_FEATURED_PUPPIES = gql`
  query featuredPuppies(
    $cursor: String
    $frontendConfigData: String
    $limit: Int
    $breed: Int
    $category: String
  ) {
    featuredPuppies(
      cursor: $cursor
      frontendConfigData: $frontendConfigData
      limit: $limit
      breed: $breed
      category: $category
    ) {
      results {
        id
        name
        slug
        isParent
        isAvailable
        gender
        price
        currency
        profilePicture {
          id
          small
          medium
          high
        }
        images {
          id
          small
        }
        videos {
          id
          medium
          high
          thumb
        }
        salePrice {
          subTotal
        }
        breed {
          id
          name
        }
        breeder {
          id
          firstName
          lastName
          companyName
        }
      }
      cursors {
        hasNext
        nextCursor
        hasPrev
        prevCursor
      }
    }
  }
`

export const STATE_LANDING = gql`
query STATE_LANDING {
  breeds {
    ...BreedsBasicData
    dogs(available: true) {
      id
      name
      slug
      gender
      salePrice {
        subTotal
      }
      breed {
        name
      }
      profilePicture {
        id
        small
        medium
        high
      }
      images {
        id
        small
      }
      videos {
        id
        medium
        high
        thumb
      }
      breeder {
        state
      }
    }
  }
  ${QUERY_CATEGORIES}
}
  ${BASIC_BREED_DATA}
`

export const GET_BREEDER_MY_PETS = gql`
  query GET_BREEDER_MY_PETS {
    getBreederMyPets {
      parents {
        id
        name
        weight
        age
        gender
        isParent
        isAvailable
        isDeleted
        registrations
        description
        createdAt
        updatedAt
        breed {
          id
          name
        }
        litter {
          id
          birthDate
        }
        profilePicture {
          id
          small
          medium
        }
      }
      puppies {
        id
        name
        isAvailable
        createdAt
        breederId
        isSold
        soldOption
        soldComments
        litter {
          id
          birthDate
          automaticallyCreated
        }
        profilePicture {
          small
          medium
        }
        dogApplications {
          id
          dogId
          order {
            id
            read
            received
            active
            updatedAt
            paymentDueDate
            shipmentStatus
            payments {
              id
              amount
              type
              status
            }
          }
        }
      }
      litters {
        id
        name
        birthDate
        automaticallyCreated
        availabilityDate
        breedId
        updatedAt
        mom {
          id
          name
          profilePicture {
            small
          }
        }
        dad {
          id
          name
          profilePicture {
            small
          }
        }
        puppies {
          id
          name
          gender
          isParent
          isAvailable
          breed {
            id
            name
          }
          profilePicture {
            small
          }
          dogApplications {
            id
            order {
              id
              received
              shipmentStatus
            }
          }
        }
      }
    }
  }
`

// TODO: Use Fragments
export const GET_BREEDER_APPLICATION_LIST = gql`
  query getBreederApplicationsList($shipmentStatus: String) {
    getBreederApplicationsList(shipmentStatus: $shipmentStatus) {
      count
      rows {
        id
        dogId
        breederId
        shippingOptionId
        orderId
        read
        approved
        canceled
        rejectionReason
        rejectionComments
        frozen
        age
        hasKids
        kidsAge
        otherPets
        firstDog
        caringKnowledge
        timeSpent
        homeType
        primaryCaretaker
        choiceReason
        extraTime
        otherInformation
        caringPlanned
        minEstimatedShippingPrice
        maxEstimatedShippingPrice
        proposedShippingPrice
        proposedShippingPriceAccepted
        shippingType
        zipcode
        name
        email
        phoneNumber
        textOk
        order {
          id
          read
          received
          shippingProvider
          shippingProviderPhone
          trackingNumber
          shippingDate
          shippingTime
          shippingNotes
          shipmentStatus
          stripeTransferId
          active
          updatedAt
          paymentDueDate
          payments {
            id
            amount
            type
            status
          }
        }
        shippingOption {
          type
        }
        breeder {
          id
          companyName
          firstName
          lastName
          phone
          companyLogo {
            small
          }
        }
        customer {
          id
          firstName
          lastName
          email
          zipcode
          country
        }
        dog {
          id
          isAvailable
          name
          price
          currency
          description
          gender
          profilePicture {
            id
            small
          }
          breed {
            id
            name
          }
          salePrice {
            price
            deposit
            transaction
            fapFee
            subTotal
            total
            reminder
          }
          litter {
            birthDate
          }
        }
      }
    }
  }
`

export const GET_BREEDER_DASHBOARD_RESERVATIONS = gql`
  query getBreederDashboardData {
    breeder {
      ...BreederReservationsData
    }
  }
  ${BREEDER_RESERVATIONS_DATA}
`

export const GET_ALL_BREEDS_NAMES = gql`
  query {
    breeds {
      ...BreedsBasicData
      profilePicture {
        small
      }
    }
  }
  ${BASIC_BREED_DATA}
`

export const BREED_DATA_FOR_VALIDATION = gql`
  query getPublicBreeds {
    breeds {
      ...BreedsBasicData
    }
  }
  ${BASIC_BREED_DATA}
`

export const CREATE_BREED_NOTIFICATION = gql`
  mutation createBreedNotification(
    $customerBreedNotifications: CreateCustomerBreedNotification
  ) {
    createCustomerBreedNotifications(
      customerBreedsNotification: $customerBreedNotifications
    ) {
      ... on CustomerBreedNotification {
        id
        name
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const PUPPIES_FOR_SALE = gql`
  query PUPPIES_FOR_SALE {
    ${QUERY_BREEDS}
    ${QUERY_CATEGORIES}
    ${QUERY_FRONTEND_CONFIG_LANDING_PHOTOS}
    ${QUERY_LOCATION}
  }
`

export const GET_RELATED_BREEDS = gql`
  query getRelatedBreeds($breedId: Int, $slice: Int, $limit: Int) {
    relatedBreeds(breedId: $breedId, slice: $slice, limit: $limit) {
      id
      name
      slug
      dogsAvailable
      profilePicture {
        id
        small
        medium
        high
      }
      dogs {
        id
        name
        slug
        gender
        isAvailable
        salePrice {
          subTotal
          puppyPrice
        }
        currency
        images {
          id
          small
          medium
          high
        }
        videos {
          id
          medium
          high
          thumb
        }
        profilePicture {
          id
          small
          medium
          high
        }
        breed {
          name
          dogsAvailable
          id
        }
        breeder {
          state
          city
        }
      }
    }
  }
`
// Related breeds List (short)
export const GET_RELATED_BREEDS_SHORT = gql`
  query getRelatedBreeds($breedId: Int, $slice: Int) {
    relatedBreeds(breedId: $breedId, slice: $slice) {
      id
      name
    }
  }
`

export const GET_BREED_NOTIFICATION = gql`
  query breedNotifications($limit: Int, $offset: Int) {
    getCustomerBreedNotifications(limit: $limit, offset: $offset) {
      id
      name
      phone
      email
      comments
      gender
      range
      bringHome
      isSubscribed
      createdAt
      customerSelectedBreeds {
        id
        breedName
      }
      customerSelectedStates {
        id
        stateName
      }
    }
  }
`
export const GET_PUPPY_NOTIFICATION = gql`
  query getCustomerPuppyNotifications($limit: Int, $offset: Int) {
    getCustomerPuppyNotifications(limit: $limit, offset: $offset) {
      id
      name
      lastName
      email
      phone
      state
      country
      comments
      textOk
      dogId
      createdAt
      googleClickId
      dog {
        id
        name
        gender
        profilePicture {
          small
        }
        breeder {
          zipcode
          id
          firstName
          lastName
          email
          city
          state
        }
        breed {
          id
          name
        }
      }
    }
  }
`
export const CUSTOMER_UNREAD_NOTIFICATIONS = gql`
  query customerUnreadNotifications {
    unreadNotifications {
      count
    }
  }
`

export const BREED_LIST_BY_STATE = gql`
  query getBreedListByState($state: String) {
    breedListByState(state: $state) {
      withDogs {
        id
        name
        slug
        count
      }
      withoutDogs {
        id
        name
        slug
      }
    }
  }
`

export const STATE_LIST_BY_BREED = gql`
  query getStateListByBreed($slug: String) {
    stateListByBreed(slug: $slug) {
      withDogs {
        id
        name
        slug
        count
      }
      withoutDogs {
        id
        name
        slug
      }
    }
  }
`
export const HOME_FEATURED_PUPPIES = gql`
  query getHomeFeaturedPuppies($limit: Int, $frontendConfigData: String) {
    homeFeaturedPuppies(
      limit: $limit
      frontendConfigData: $frontendConfigData
    ) {
      id
      name
      slug
      gender
      price
      currency
      isAvailable
      isSold
      discount(active: true) {
        id
        code
        startDate
        endDate
        applyDiscountByDefault
        allowToAllDogs
        amount
      }
      profilePicture {
        id
        small
      }
      images {
        id
        small
      }
      videos {
        id
        medium
        high
        thumb
      }
      salePrice {
        subTotal
        puppyPrice
      }
      breed {
        id
        name
      }
      dogApplications {
        id
        dogId
        order {
          id
          read
          received
          active
          updatedAt
          paymentDueDate
          shipmentStatus
          payments {
            id
            amount
            type
            status
          }
        }
      }
    }
  }
`

export const VALIDATE_DISCOUNT_CODE = gql`
  query validateDiscountCode(
    $code: String
    $dogApplicationId: Int
    $dogId: Int
    $shouldReduce: Boolean
  ) {
    validateDiscountCode(
      code: $code
      dogApplicationId: $dogApplicationId
      dogId: $dogId
      shouldReduce: $shouldReduce
    ) {
      id
      success
      message
      amount
      code
    }
  }
`

export const REMOVE_APPLIED_DISCOUNT = gql`
  mutation removeAppliedDiscount($discountId: Int, $dogApplicationId: Int) {
    removeAppliedDiscount(
      discountId: $discountId
      dogApplicationId: $dogApplicationId
    ) {
      ... on RemoveAppliedDiscountResult {
        ... on Result {
          result
        }
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_RANDOM_DISCOUNT = gql`
  query getRandomDiscount {
    randomDiscount {
      id
      amount
      code
      startDate
      endDate
      terms
      bannerText
      applyDiscountByDefault
      allowToAllDogs
      customerSpecific
      breeds {
        id
      }
      dogs {
        id
      }
    }
  }
`

export const GET_RANDOM_BREED_DISCOUNT = gql`
  query getRandomBreedDiscount {
    randomBreedDiscount {
      id
      amount
      code
      startDate
      endDate
      terms
      applyDiscountByDefault
      allowToAllDogs
      breeds {
        id
        name
      }
    }
  }
`
export const GET_POST_DOGS = gql`
  query breed($id: Int, $slug: String, $limit: Int) {
    breed(id: $id, slug: $slug) {
      id
      dogs(available: true, first: $limit) {
        id
        name
        slug
        gender
        viewScore
        isAvailable
        breed {
          name
        }
        images {
          small
        }
        videos {
          id
          medium
          high
          thumb
        }
        profilePicture {
          small
        }
        salePrice {
          subTotal
          puppyPrice
        }
      }
    }
  }
`
export const GET_BREED_DATA = gql`
  query getBreedData($id: Int, $slug: String) {
    breed(id: $id, slug: $slug) {
      ...BreedsBasicData
      profilePicture {
        id
        small
        medium
        high
      }
      coverPicture {
        id
        small
        medium
        high
      }
      discount(active: true) {
        id
        code
        startDate
        endDate
        applyDiscountByDefault
        allowToAllDogs
        amount
        breeds {
          id
          name
        }
      }
    }
  }
  ${BASIC_BREED_DATA}
`
export const BREED_LIST_EXPLORER = gql`
  query getBreeds($limit: Int, $offset: Int) {
    breedsList(limit: $limit, offset: $offset) {
      ...BreedsBasicData
      profilePicture {
        id
        small
      }
    }
  }
  ${BASIC_BREED_DATA}
`

export const CREATE_REQUEST_USER = gql`
  mutation createRequestAboutPuppy($request: CreateReqAboutPuppy) {
    createRequestAboutPuppy(request: $request) {
      ... on CustomerPuppyNotifications {
        name
        lastName
        email
        phone
        state
        country
        comments
        textOk
        dogId
        dog {
          name
          gender
          breeder {
            zipcode
            id
            firstName
            lastName
            email
            city
            state
          }
          breed {
            id
            name
          }
        }
      }
      ... on Error {
        errorMessage
        errorList {
          field
          error
        }
      }
    }
  }
`
export const GET_VETERINARIANS = gql`
  query getVeterinarians($location: String) {
    getVeterinarians(location: $location) {
      id
      alias
      name
      image_url
      is_closed
      url
      review_count
      rating
      coordinates {
        latitude
        longitude
      }

      location {
        address1
        address2
        zip_code
        city
        state
        country
      }
      phone
      display_phone
      distance
    }
  }
`
export const UPDATE_CREDOVA = gql`
  mutation updateCredovaPayment($credovaPayment: UpdateCredovaPayment) {
    updateCredovaPayment(credovaPayment: $credovaPayment) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_MAWOO_DOG_PRICE = gql`
  query getMawooDogPrice($price: Int) {
    mawooDogPrice(price: $price) {
      dogPrice
      fapFees
      puppyPrice
    }
  }
`
export const GET_BREEDER_DOG_PRICE = gql`
  query getBreederDogPrice($price: Int) {
    breederDogPrice(price: $price) {
      mawooDogPrice
      fapFees
      breederPrice
    }
  }
`

// Google and Facebook Click ID

export const CREATE_GOOGLE_CLICK_ID = gql`
  mutation createGoogleClickId($googleClickId: CreateGoogleClickId) {
    createGoogleClickId(googleClickId: $googleClickId) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPDATE_GOOGLE_CLICK_ID = gql`
  mutation updateGoogleClickId(
    $id: Int
    $isPaid: Boolean
    $isFinanciallyApproved: Boolean
  ) {
    updateGoogleClickId(
      id: $id
      isPaid: $isPaid
      isFinanciallyApproved: $isFinanciallyApproved
    ) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_GOOGLE_CLICK_ID = gql`
  query getGoogleClickId($id: Int) {
    googleClickId(id: $id) {
      id
      clickId
      appId
      isPaid
      isFinanciallyApproved
    }
  }
`

export const CREATE_FACEBOOK_CLICK_ID = gql`
  mutation createFacebookClickId($facebookClickId: CreateFacebookClickId) {
    createFacebookClickId(facebookClickId: $facebookClickId) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPDATE_FACEBOOK_CLICK_ID = gql`
  mutation updateFacebookClickId($id: Int, $isPaid: Boolean) {
    updateFacebookClickId(id: $id, isPaid: $isPaid) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_FACEBOOK_CLICK_ID = gql`
  query getFacebookClickId($id: Int) {
    facebookClickId(id: $id) {
      id
      clickId
      appId
      isPaid
    }
  }
`

export const REMOVE_BREED_BREEDER = gql`
  mutation removeBreedBreeder($breedId: Int) {
    removeBreedBreeder(breedId: $breedId) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const REMIND_ME_LATER_BREED_NOTIFICATION = gql`
  mutation remindMe($data: RemindMeLaterBreedNotification) {
    remindMeLaterBreedNotification(remindMeLaterBreedNotification: $data) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const REMOVE_CUSTOMER_BREED_NOTIFICATION = gql`
  mutation remove($data: RemoveCustomerBreedNotification) {
    removeCustomerBreedNotification(removeCustomerBreedNotification: $data) {
      ... on Result {
        result
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_NEW_ALGORITHM_DOGS = gql`
  query getDogsNewAlgorithm($data: GetDogsNewAlgorithmInputType) {
    getDogsNewAlgorithm(data: $data) {
      dogsNewAlgorithm {
        dogId
        dogName
        dogPrice
        profilePictureUrl
        daysOld
        brandNewScore
        priceScore
        over3MonthsViewScore
        over1MonthViewScore
        last3MonthsViewScore
        last1MonthViewScore
        lastWeekViewScore
        totalViewScore
        viewWeightedScore
        over1MonthDogApplicationScore
        last1MonthDogApplicationScore
        lastWeekDogApplicationScore
        totalDogApplicationScore
        dogApplicationWeightedScore
        totalScore
      }
    }
  }
`
