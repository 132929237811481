import { useEffect } from 'react'
import Cookies from 'universal-cookie'
import { initializeApollo } from './apolloClient'
import { CREATE_GOOGLE_CLICK_ID } from '@/graphql/index'

export const useGoogleClickId = () => {
  useEffect(() => {
    if (window?.location?.search) {
      const params = new URLSearchParams(window.location.search)

      const id = params.get('gclid')
      if (id) {
        const cookies = new Cookies()
        cookies.set('gclid', id, {
          path: '/',
          maxAge: 60 * 60 * 24 * 90, // 90 days
        })
      }
    }
  }, [])
}

export const getGoogleClickId = () => {
  const cookies = new Cookies()
  const id = cookies.get('gclid')

  return id || null
}

export const saveGoogleClickId = async (result) => {
  const googleClickId = getGoogleClickId()

  if (googleClickId) {
    const apolloClient = initializeApollo()
    const { data } = await apolloClient.mutate({
      mutation: CREATE_GOOGLE_CLICK_ID,
      variables: {
        googleClickId: {
          clickId: googleClickId,
          appId: result?.createDogApplication?.id,
          isPaid: false,
        },
      },
    })

    return data
  }
}
